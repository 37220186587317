




import { Component, Vue } from 'vue-property-decorator'
import WorkingIndex from '@/components/common/WorkingIndex.vue'

  @Component({ components: { WorkingIndex } })
export default class MonitorIndex extends Vue {

}
